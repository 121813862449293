import { skipToken } from "@reduxjs/toolkit/query";
import { createContext, useContext } from "react";
import { EntityTypeName, EntityWhereInput, LinktoUnion, Stage } from "../../hygraph/vo";
import { useLinksQuery } from "../hooks/linkApi";
import { notNil } from "../../common/helpers/isNil";

export type LinkEntityMapType = { [key: string]: LinktoUnion };

export const LinkEntityMapContext = createContext<LinkEntityMapType | null>(null);

export function useLinkEntityMapContext() {
  return useContext(LinkEntityMapContext);
}

export const LinkEntityMap: React.FC<{ children?: React.ReactNode; links: (LinktoUnion | undefined | null)[] }> = ({
  children,
  links,
}) => {
  const linksAsWhereInput = links
    .map((link): EntityWhereInput | null =>
      link && link.__typename
        ? { id: link.id, typename: EntityTypeName[link.__typename], stage: Stage.Published }
        : null,
    )
    .filter(notNil);
  const hasLinks = linksAsWhereInput.length > 0;
  const { data, isFetching } = useLinksQuery(hasLinks ? { linksWhere: linksAsWhereInput } : skipToken);

  if (isFetching) return null;

  const linkEntityMap = hasLinks
    ? data!.entities.filter(notNil).reduce((acc, entity) => {
        acc[entity.id] = entity;
        return acc;
      }, {} as LinkEntityMapType)
    : ({} as LinkEntityMapType);

  return <LinkEntityMapContext.Provider value={linkEntityMap}>{children}</LinkEntityMapContext.Provider>;
};
