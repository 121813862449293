import Locale from "../../common/constants/Locale";
import { buildIndexName, Indices } from "../services/search";
import { AlgoliaAnalyticsEvent } from "../hooks/useAlgoliaAnalytics";

export const buildConversionEvent = ({
  language,
  objectID,
  index,
  userId,
  eventName,
  positions,
  queryID,
}: {
  language: Locale;
  objectID: number | string;
  index: Indices;
  userId: string | undefined;
  eventName: string;
  positions?: number[];
  queryID?: string;
}): AlgoliaAnalyticsEvent => {
  return {
    userToken: userId,
    index: buildIndexName(index, language),
    eventName,
    objectIDs: [`${objectID}`],
    positions,
    queryID,
  };
};
