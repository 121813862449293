import React from "react";
import AlgoliaVehicleSearchCarCard from "../../vehicle/components/AlgoliaVehicleSearchCarCard";
import { Indices } from "../../algolia/services/search";
import { VehicleSearchResponse, VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import Locale from "../../common/constants/Locale";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";
import { buildAaEventPosition } from "../../algolia/scripts/buildAaEventPosition";
import { buildConversionEvent } from "../../algolia/scripts/buildConversionEvent";

type Props = {
  index: number;
  language: Locale;
  page: number;
  userId?: string;
  vehicle: VehicleSearchResponseItem;
  vehicleData: VehicleSearchResponse;
};

const SearchResultCarCard: React.FC<Props> = ({ index, language, page, userId, vehicle, vehicleData }) => {
  const aaEvent = buildConversionEvent({
    language,
    objectID: vehicle.objectID,
    userId,
    eventName: "clickVehicle",
    index: Indices.vehicle,
    positions: buildAaEventPosition(vehicleData, index),
    queryID: vehicleData.queryID!,
  });
  return (
    <AlgoliaVehicleSearchCarCard
      aaEvent={aaEvent}
      animationIndex={index}
      firstImageLoading={index === 0 ? "eager" : "lazy"}
      page={page}
      trackingReferrer={TrackingReferrer.None}
      vehicle={vehicle}
    />
  );
};

export default SearchResultCarCard;
