import { gql } from "graphql-request";
import { EntityWhereInput, LinktoUnion } from "../../../modules/hygraph/vo";
import { hygraphApi } from "../../../modules/hygraph/services/hygraphApi";
import linkToUnionFragment from "../../../modules/hygraph/fragments/linkToUnionFragment";
import pageNestedSlugFragment from "../../hygraph/fragments/pageNestedSlugFragment";

export const brandPageExtensionsApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    links: builder.query<{ entities: LinktoUnion[] }, { linksWhere: EntityWhereInput[] }>({
      providesTags: ["languageDependent"],
      query: ({ linksWhere }) => {
        return {
          document: gql`
            ${pageNestedSlugFragment}
            ${linkToUnionFragment}
            query LinkToUnion($linksWhere: [EntityWhereInput!]!) {
              entities(where: $linksWhere) {
                ...LinkToUnion
              }
            }
          `,
          variables: {
            linksWhere,
          },
        };
      },
    }),
  }),
});

export const { useLinksQuery } = brandPageExtensionsApi;
