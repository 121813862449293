import { FILTER_DEFINITIONS } from "../services/filterDefinition";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../services/filterTypeConfiguration";
import { getConfigurationCount } from "./filterTypeHelpers";

function getAggregatedConfigurationCount(appliedFilters: Record<string, string[] | string>, query: string) {
  const hasAppliedQuery = query !== "";

  const appliedOverlayFilterCount = Array.from(Object.entries(appliedFilters))
    .map(([queryParam, value]) => {
      const type = FILTER_DEFINITIONS[queryParam].type;
      const filterType = FILTER_TYPE_CONFIGURATION_MAP[type];
      return getConfigurationCount(filterType, value);
    })
    .reduce((agg, count) => agg + count, 0);

  return { appliedOverlayFilterCount, hasAppliedQuery };
}

export default getAggregatedConfigurationCount;
