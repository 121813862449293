import algoliaAnalytics, { InsightsMethodMap } from "search-insights";
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, Indices } from "../services/search";
import useCookieConsent from "../../common/hooks/useCookieConsent";
import { ActionItemType } from "../constants/ActionItemType";
import Locale from "../../common/constants/Locale";
import { ConversionEventName } from "../constants/ConversionEventName";
import { buildConversionEvent } from "../scripts/buildConversionEvent";

export type AlgoliaAnalyticsEvent = {
  index: string;
  objectIDs: string[];
  userToken: string | undefined;
  eventName?: string;
  queryID?: string;
  positions?: number[];
};

const initAnalytics = () => {
  algoliaAnalytics("init", {
    appId: ALGOLIA_APP_ID,
    apiKey: ALGOLIA_API_KEY,
  });

  return algoliaAnalytics;
};

export type EventInfo = {
  eventMethod: keyof InsightsMethodMap;
  event: AlgoliaAnalyticsEvent;
};

export const buildViewedObjectIDsEvent = (event: AlgoliaAnalyticsEvent, actionItemType: ActionItemType): EventInfo => {
  return {
    eventMethod: "viewedObjectIDs",
    event: { ...event, eventName: `view${actionItemType}` },
  };
};

export const buildConvertedObjectIDsEvent = (
  language: Locale,
  objectID: number,
  userId: string | undefined,
  eventName: ConversionEventName,
): EventInfo => {
  const aaEvent = buildConversionEvent({ language, objectID, userId, eventName, index: Indices.vehicle });
  return {
    eventMethod: "convertedObjectIDs",
    event: aaEvent,
  };
};

export const buildClickedObjectIDsAfterSearchEvent = (
  event: AlgoliaAnalyticsEvent,
  actionItemType: ActionItemType,
): EventInfo => {
  return {
    eventMethod: "clickedObjectIDsAfterSearch",
    event: { ...event, eventName: `click${actionItemType}` },
  };
};

function useAlgoliaAnalytics() {
  const { permitAnalytics } = useCookieConsent();
  return (eventInfo: EventInfo) => {
    const { eventMethod, event } = eventInfo;
    if (permitAnalytics) {
      const algoliaAnalytics = initAnalytics();
      algoliaAnalytics(eventMethod, event);
    }
  };
}

export default useAlgoliaAnalytics;
