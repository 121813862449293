import { gql } from "graphql-request";
import { buildNestedSlugObtainer } from "../../../static-page/helpers/hygraphPageHierarchyHelpers";

// Load only one level deep until query is optimized better
//export const PAGE_NESTING_DEPTH = 4;
export const PAGE_NESTING_DEPTH = 2;

const pageNestedSlugFragment = gql`
  fragment PageNestedSlug on Page {
    ${buildNestedSlugObtainer(PAGE_NESTING_DEPTH)}
  }
`;

export default pageNestedSlugFragment;
