import { ReactNode, useEffect } from "react";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/query";
import { useVehiclesQuery } from "../../hygraph/services/vehicleDetailApi";
import useViewedVehicleList from "../hooks/useViewedVehicleList";

function ViewedVehiclesHandler(): ReactNode {
  const { lastViewedList, setLastViewedList, ids } = useViewedVehicleList();
  const { data: lastShownVehicles, status } = useVehiclesQuery(ids.length > 0 ? ids : skipToken);

  const disabledVehicleIds = lastShownVehicles
    ?.filter(vehicle => !vehicle.enabled || vehicle.reserved)
    .map(vehicle => `${vehicle.vehicleId}`);

  useEffect(() => {
    if (
      status === QueryStatus.fulfilled &&
      (disabledVehicleIds?.length || (lastShownVehicles && lastViewedList.length > lastShownVehicles.length))
    ) {
      const newVehicles = lastViewedList.filter(vehicle => {
        return (
          !disabledVehicleIds?.includes(vehicle.id) &&
          lastShownVehicles?.map(({ vehicleId }) => `${vehicleId}`).includes(vehicle.id)
        );
      });

      setLastViewedList(newVehicles);
    }
  }, [status, lastShownVehicles, disabledVehicleIds, lastViewedList, setLastViewedList]);

  return null;
}

export default ViewedVehiclesHandler;
