import React, { useRef } from "react";
import i18next from "i18next";
import classNames from "classnames";
import SingleOptionSelect, { SelectItem } from "../../visual-components/components/form/SingleOptionSelect";
import usePopupTrigger from "../../common/hooks/usePopupTrigger";
import Button, { LinkIconButton } from "../../visual-components/components/Button";
import { currentSortValueTrack } from "../../analytics/scripts/searchTrack/currentSortValueTrack";
import StatusSmall from "../../../processes/matchmaker/components/access/StatusSmall";

type Props = {
  resetFilters: () => void;
  showSearchOverlay: () => void;
  showSaveSearchOverlay?: () => void;
  configurationCount: number;
  hasAppliedQuery: boolean;
  sortCriteria: string;
  setSortCriteria: (criteria: string) => void;
  sortOptions: { value: string; label: string }[];
  hasMatchmaker: boolean;
};

const FilterTool: React.FC<Props> = ({
  resetFilters,
  showSearchOverlay,
  showSaveSearchOverlay,
  configurationCount,
  hasAppliedQuery,
  sortCriteria,
  setSortCriteria,
  sortOptions,
  hasMatchmaker,
}) => {
  const sortCriteriaValue = {
    value: sortCriteria,
    name: sortOptions.find(({ value }) => value === sortCriteria)?.label ?? "",
  };

  const sortCriteriaOptions = sortOptions.map(({ value, label }) => ({
    value,
    name: label,
  }));

  const sortCriteriaOnChange = ({ value }: SelectItem) => {
    value && setSortCriteria(value);
  };

  const filterElementRef = useRef<HTMLDivElement>(null);
  const scrolled = usePopupTrigger(filterElementRef, true);
  const isFilterApplied = configurationCount > 0 || hasAppliedQuery;

  return (
    <>
      <dialog className={classNames("filter-tool-popup", { open: scrolled })}>
        <Button
          className="filter-tool__btn-filter"
          icon="filter"
          toast={configurationCount}
          onClick={showSearchOverlay}
        >
          {i18next.t("FILTER")}
        </Button>
        <div className="sort-dropdown">
          <SingleOptionSelect
            autoSize
            bg
            options={sortCriteriaOptions}
            value={sortCriteriaValue}
            onChange={sortCriteriaOnChange}
          />
        </div>
      </dialog>
      <div className="filter-tool-wrap">
        <ul className="filter-tool__list filter-tool__left">
          {hasMatchmaker ? (
            <li>
              <StatusSmall />
            </li>
          ) : null}
          {showSaveSearchOverlay ? (
            <li>
              <LinkIconButton icon="bell" onClick={showSaveSearchOverlay}>
                {i18next.t("SAVE SEARCH")}
              </LinkIconButton>
            </li>
          ) : null}
          {isFilterApplied ? (
            <li className="filter-tool__reset">
              <LinkIconButton icon="close" onClick={resetFilters}>
                {i18next.t("DELETE ALL FILTERS")}
              </LinkIconButton>
            </li>
          ) : null}
        </ul>
        <div className="filter-tool__right">
          <Button
            className="filter-tool__btn-filter"
            icon="filter"
            toast={configurationCount}
            onClick={showSearchOverlay}
          >
            {i18next.t("FILTER")}
          </Button>
          <div ref={filterElementRef} className="sort-dropdown">
            <SingleOptionSelect
              autoSize
              bg
              options={sortCriteriaOptions}
              value={sortCriteriaValue}
              onChange={(value: SelectItem) => {
                sortCriteriaOnChange(value);
                currentSortValueTrack(value.value);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterTool;
