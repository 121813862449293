import { isDateInRange } from "../../common/helpers/date";
import { PaPromotion } from "../../hygraph/vo";

export function isPaPromotionValid(paPromotion?: PaPromotion | null): boolean {
  if (!paPromotion) {
    return true;
  }
  if (!paPromotion.active) {
    return false;
  }

  const compareDate = new Date();
  return isDateInRange(compareDate, paPromotion.validFromDate, paPromotion.validUntilDate);
}
