import React from "react";
import {
  AbsoluteUrlLink,
  AppRoute,
  ArticleLink,
  BrandLink,
  DealerLink,
  Entity,
  LinktoUnion,
  MailtoLink,
  Maybe,
  ModelLink,
  PageLink,
  TelLink,
} from "../../hygraph/vo";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { logger } from "../../common/scripts/logger";
import useVehicleComparisonListLink from "../../vehicle/hooks/useVehicleComparisonListLink";
import useVehicleFavoritesListLink from "../../vehicle/hooks/useVehicleFavoritesListLink";
import { HistoryState } from "../../../router/constants/State";
import { ctaOutboundLinkTrack } from "../../analytics/scripts/navigationTrack/ctaOutboundLinkTrack";
import useCurrentPage from "../../../router/hooks/useCurrentPage";
import { useLinkEntityMapContext } from "../helpers/LinkEntityMap";

// TODO: Separate logic for generating link into separate hook,
// only use useContentButton when other button props are required
function useContentButton({
  linkEntity,
  link,
  absolute = false,
  base,
  clickTrackText,
}: {
  linkEntity?: Maybe<Entity>;
  link?: Maybe<LinktoUnion>;
  absolute?: boolean;
  base?: string;
  clickTrackText?: string;
}): {
  to: string;
  onClick?: React.MouseEventHandler | undefined;
  state?: Record<string, number | boolean> | undefined;
  target?: string;
  rel?: string;
  disabled?: boolean;
} {
  const {
    brandListPath,
    cockpitPath,
    dealerLocatorPath,
    vehicleEvaluationPath,
    homePath,
    magazinePath,
    savedSearchesPath,
    searchPath,
    vehicleEstimationPath,
    vehicleEvaluationIntroPath,
    tourChecklistsPath,
    brandPath,
    modelPath,
    staticPagePath,
    magazineArticlePath,
    matchmakerPath,
    dealerDetailPath,
    promotionsPath,
  } = usePathBuilder();
  const {
    to: comparisonPath,
    onClick: comparisonOnClick,
    state: comparisonState,
  } = useVehicleComparisonListLink({
    base,
    absolute: true,
  });
  const { to: favoritesPath, onClick: favoritesOnClick } = useVehicleFavoritesListLink({
    base,
    absolute: true,
  });
  const linkEntityMap = useLinkEntityMapContext();
  const currentPage = useCurrentPage();

  const unavailableLink = { to: "#", disabled: true };

  function getAppLinkTo(appRoute: AppRoute, params?: string): string {
    switch (appRoute) {
      case AppRoute.BrandList:
        return brandListPath({ absolute, base, params });
      case AppRoute.Cockpit:
        return cockpitPath({ absolute, base, params });
      case AppRoute.DealerLocator:
        return dealerLocatorPath({ absolute, base, params });
      case AppRoute.Favorites:
        return favoritesPath;
      case AppRoute.FfuVehicleEvaluation:
        return vehicleEvaluationPath({ absolute, base, params });
      case AppRoute.Home:
        return homePath({ absolute, base, params });
      case AppRoute.MagazineOverview:
        return magazinePath({ absolute, base, params });
      case AppRoute.SavedSearches:
        return savedSearchesPath({ absolute, base, params });
      case AppRoute.Search:
        return searchPath({ absolute, base, params });
      case AppRoute.VehicleComparison:
        return comparisonPath;
      case AppRoute.VehicleEstimation:
        return vehicleEstimationPath({ absolute, base, params });
      case AppRoute.VehicleEvaluationIntro:
        return vehicleEvaluationIntroPath({ absolute, base, params });
      case AppRoute.TourChecklist:
        return tourChecklistsPath({ absolute, base, params });
      case AppRoute.MatchMaker:
        return matchmakerPath({ absolute, base, params });
      case AppRoute.PaPromotionList:
        return promotionsPath({ absolute, base, params });

      default:
        logger.warn(`No route defined for appRoute: (${appRoute}).`);
        return "#";
    }
  }

  function getBrandLinkTo(brandLink: BrandLink): string {
    if (!brandLink.brand?.slug) {
      logger.warn(`No slug defined for brand: (${brandLink.brand}).`);
      return "#";
    }

    return brandPath({
      absolute,
      base,
      slug: brandLink.brand.slug,
    });
  }

  function getModelLinkTo(modelLink: ModelLink): string {
    if (!modelLink.model?.slug || !modelLink.model.brand?.slug) {
      logger.warn(`No slug defined for model: (${modelLink.model}).`);
      return "#";
    }

    return modelPath({
      absolute,
      base,
      brandSlug: modelLink.model.brand.slug,
      modelSlug: modelLink.model.slug,
    });
  }

  function getPageLinkTo(pageLink: PageLink): string {
    if (!pageLink.page?.parent || !pageLink.page?.slug) {
      logger.warn(`No slug or parent defined for page: (${pageLink.page}).`, {
        pageLinkId: pageLink.id,
        pageLink,
      });
      return "#";
    }

    return staticPagePath({
      absolute,
      base,
      parent: pageLink.page.parent!,
      slug: pageLink.page.slug,
    });
  }

  function getArticleLinkTo(articleLink: ArticleLink): string {
    if (!articleLink.article?.slug || !articleLink.article?.id) {
      logger.warn(`No slug defined for article: (${articleLink.article}).`);
      return "#";
    }

    return magazineArticlePath({
      id: articleLink.article.id,
      absolute,
      slug: articleLink.article.slug,
    });
  }

  function getDealerLinkTo(dealerLink: DealerLink): string {
    if (!dealerLink.dealer?.slug || !dealerLink.dealer?.insideId) {
      logger.warn(`No slug or insideId defined for dealer: (${dealerLink.dealer}).`);
      return "#";
    }

    return dealerDetailPath({
      id: dealerLink.dealer.insideId,
      slug: dealerLink.dealer.slug,
      tab: dealerLink.tab,
      params: dealerLink.additionalQueryParameters ?? undefined,
    });
  }

  function getMailtoLinkTo(mailtoLink: MailtoLink): string {
    return `mailto:${mailtoLink.emailAddress}`;
  }

  function getTelLinkTo(telLink: TelLink): string {
    return `tel:${telLink.phoneNumber}`;
  }

  function getAbsoluteUrlLinkTo(absoluteUrlLink: AbsoluteUrlLink): string {
    return absoluteUrlLink.urlLink.url;
  }

  function getAppLinkOnClick(appRoute: AppRoute): React.MouseEventHandler | undefined {
    switch (appRoute) {
      case AppRoute.Favorites:
        return favoritesOnClick;
      case AppRoute.VehicleComparison:
        return comparisonOnClick;

      default:
        return undefined;
    }
  }

  function getAppLinkState(appRoute: AppRoute): Record<string, number | boolean> | undefined {
    switch (appRoute) {
      case AppRoute.VehicleEvaluationIntro:
      case AppRoute.VehicleEstimation:
      case AppRoute.FfuVehicleEvaluation:
      case AppRoute.TourChecklist:
        return { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 };
      case AppRoute.VehicleComparison:
        return comparisonState;

      default:
        return undefined;
    }
  }

  function getAppLinkRel(appRoute: AppRoute): string | undefined {
    switch (appRoute) {
      case AppRoute.VehicleEvaluationIntro:
      case AppRoute.VehicleEstimation:
      case AppRoute.FfuVehicleEvaluation:
        return "nofollow";
      default:
        return undefined;
    }
  }

  function getLinkData(link: LinktoUnion): ReturnType<typeof useContentButton> {
    switch (link.__typename) {
      case "AppLink":
        return {
          to: getAppLinkTo(link.appRoute, link.additionalQueryParameters || undefined),
          onClick: getAppLinkOnClick(link.appRoute),
          state: getAppLinkState(link.appRoute),
          rel: getAppLinkRel(link.appRoute),
        };
      case "BrandLink":
        return { to: getBrandLinkTo(link) };
      case "ModelLink":
        return { to: getModelLinkTo(link) };
      case "PageLink":
        return { to: getPageLinkTo(link) };
      case "ArticleLink":
        return { to: getArticleLinkTo(link) };
      case "DealerLink":
        return { to: getDealerLinkTo(link) };
      case "MailtoLink":
        return { to: getMailtoLinkTo(link) };
      case "TelLink":
        return { to: getTelLinkTo(link) };
      case "AbsoluteUrlLink":
        const to = getAbsoluteUrlLinkTo(link);
        return {
          to,
          target: link.openInNewTab ? "_blank" : undefined,
          onClick: (ev: React.MouseEvent<HTMLSpanElement>) => {
            ctaOutboundLinkTrack({ ev, pageType: currentPage, clickTrackText, targetUrl: to });
          },
        };
      default:
        logger.warn(`No link routing defined for links of type: ${link.__typename}`);
        return unavailableLink;
    }
  }

  if (link) {
    return getLinkData(link);
  } else if (linkEntity && linkEntityMap) {
    const link = linkEntityMap[linkEntity.id];
    if (link) {
      return getLinkData(link);
    }
  }

  return unavailableLink;
}

export default useContentButton;
