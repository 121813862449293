import React from "react";
import classNames from "classnames";

export const IMAGE_PROVIDER_MAGAZINE_BASE_URL = "https://cmsimg.imgix.net/";

type ExtensiveImageSizeDefinition = {
  aspectRatio: string;
  desktopLrg: number;
  desktop: number;
  desktopSml: number;
  tablet: number;
  phoneLrg: number;
  phone: number;
};

type SimpleImageSizeDefinition = { aspectRatio: string; width: number };

type ImageSizeDefinition = ExtensiveImageSizeDefinition | SimpleImageSizeDefinition;

const isExtensiveImageSizeDefinition = (
  imageSizeDefinition: ImageSizeDefinition,
): imageSizeDefinition is ExtensiveImageSizeDefinition => {
  return "desktopLrg" in imageSizeDefinition;
};

export enum IMAGE_TRANSFORM_TYPES {
  magazineDetailImage = "magazineDetailImage",
  magazineDetailImageFullRes = "magazineDetailImageFullRes",
  magazineDetailImageWide = "magazineDetailImageWide",
  magazineTeaserImage = "magazineTeaserImage",
  cardImage = "cardImage",
  thumbnailImage = "thumbnailImage",
  overlayThumbnailImage = "overlayThumbnailImage",
  logoListThumbnailImage = "logoListThumbnailImage",
  homeHeroImage = "homeHeroImage",
  detailHeroImage = "detailHeroImage",
  detailHeroImageFullRes = "detailHeroImageFullRes",
  brandLogo = "brandLogo",
  brandModelImage = "brandModelImage",
  liveSearchSquare = "liveSearchSquare",
  dealerLogo = "dealerLogo",
  employeePictureSquare = "employeePictureSquare",
  stickyBarVehicleImage = "stickyBarVehicleImage",
  cardWrapShortDealerImage = "cardWrapShortDealerImage",
  cardWrapShortCarImage = "cardWrapShortCarImage",
  cardWrapShortModelDealerImage = "cardWrapShortModelImage",
  marketplaceNavigationWide = "marketplaceNavigationWide",
  marketplaceNavigationNarrow = "marketplaceNavigationNarrow",
  emptyState = "emptyState",
}

export enum IMAGE_FIT_TYPES {
  crop = "crop",
  fill = "fill",
}

export enum IMAGE_FILL_COLORS {
  transparent = "transparent",
  white = "white",
}

const IMAGE_TRANSFORMER_MAP: Record<IMAGE_TRANSFORM_TYPES, ImageSizeDefinition> = {
  homeHeroImage: {
    aspectRatio: "50:17",
    desktopLrg: 1275,
    desktop: 1180,
    desktopSml: 944,
    tablet: 1023,
    phoneLrg: 700,
    phone: 450,
  },
  detailHeroImage: {
    aspectRatio: "4:3",
    desktopLrg: 628,
    desktop: 580,
    desktopSml: 462,
    tablet: 1023,
    phoneLrg: 699,
    phone: 449,
  },
  detailHeroImageFullRes: {
    aspectRatio: "4:3",
    desktopLrg: 628 * 2,
    desktop: 580 * 2,
    desktopSml: 462 * 2,
    tablet: 1023 * 2,
    phoneLrg: 699 * 2,
    phone: 449 * 2,
  },
  magazineDetailImage: {
    aspectRatio: "16:9",
    desktopLrg: 940,
    desktop: 876,
    desktopSml: 719,
    tablet: 1023,
    phoneLrg: 700,
    phone: 450,
  },
  magazineDetailImageFullRes: {
    aspectRatio: "16:9",
    desktopLrg: 940 * 2,
    desktop: 876 * 2,
    desktopSml: 719 * 2,
    tablet: 1023 * 2,
    phoneLrg: 700 * 2,
    phone: 450 * 2,
  },
  magazineDetailImageWide: {
    aspectRatio: "16:9",
    desktopLrg: 1276,
    desktop: 1180,
    desktopSml: 944,
    tablet: 1023,
    phoneLrg: 700,
    phone: 450,
  },
  magazineTeaserImage: {
    aspectRatio: "16:9",
    desktopLrg: 628,
    desktop: 578,
    desktopSml: 460,
    tablet: 481,
    phoneLrg: 260,
    phone: 260,
  },
  brandModelImage: {
    aspectRatio: "16:9",
    desktopLrg: 410,
    desktop: 410,
    desktopSml: 410,
    tablet: 318,
    phoneLrg: 318,
    phone: 318,
  },
  liveSearchSquare: {
    aspectRatio: "16:9",
    desktopLrg: 302,
    desktop: 260,
    desktopSml: 198,
    tablet: 250,
    phoneLrg: 250,
    phone: 198,
  },
  cardImage: {
    aspectRatio: "4:3",
    desktopLrg: 304,
    desktop: 280,
    desktopSml: 301,
    tablet: 481,
    // Same size as detailHeroImage to prevent reloading image when going from card to vdp
    phoneLrg: 699,
    phone: 449,
  },
  thumbnailImage: { width: 124, aspectRatio: "4:3" },
  overlayThumbnailImage: { width: 124 * 2, aspectRatio: "4:3" },
  logoListThumbnailImage: {
    width: 88,
    aspectRatio: "16:9",
  },
  brandLogo: {
    width: 320,
    aspectRatio: "16:9",
  },
  dealerLogo: {
    width: 320,
    aspectRatio: "16:9",
  },
  employeePictureSquare: {
    width: 134,
    aspectRatio: "1:1",
  },
  stickyBarVehicleImage: {
    width: 75,
    aspectRatio: "4:3",
  },
  cardWrapShortDealerImage: {
    width: 84,
    aspectRatio: "16:9",
  },
  cardWrapShortCarImage: {
    width: 84,
    aspectRatio: "4:3",
  },
  cardWrapShortModelImage: {
    width: 84,
    aspectRatio: "16:9",
  },
  marketplaceNavigationWide: {
    aspectRatio: "50:17",
    desktopLrg: 983,
    desktop: 628,
    desktopSml: 628,
    tablet: 983,
    phoneLrg: 983,
    phone: 459,
  },
  marketplaceNavigationNarrow: {
    aspectRatio: "4:3",
    desktopLrg: 983,
    desktop: 628,
    desktopSml: 628,
    tablet: 983,
    phoneLrg: 983,
    phone: 459,
  },
  emptyState: {
    aspectRatio: "16:9",
    desktopLrg: 520,
    desktop: 520,
    desktopSml: 382,
    tablet: 398,
    phoneLrg: 263,
    phone: 409,
  },
};

// See: https://docs.imgix.com/apis/rendering/stylize/monochrome
const MONOCHROME_HEX_COLOR = "676D81";

type BaseProps = {
  alt?: string;
  imageSizes: IMAGE_TRANSFORM_TYPES;
  baseSrc: string;
  className?: string;
  contain?: boolean;
  loading?: "lazy" | "eager";
  grayscale?: boolean;
  invert?: boolean;
};

type CropProps = BaseProps & {
  fit: IMAGE_FIT_TYPES.crop;
};
type FillProps = BaseProps & {
  fit: IMAGE_FIT_TYPES.fill;
  fillColor: IMAGE_FILL_COLORS;
};
type Props = CropProps | FillProps;

const isFillProps = (props: Props): props is FillProps => {
  return props.fit === IMAGE_FIT_TYPES.fill;
};

export const buildSrc = ({
  width,
  aspectRatio,
  src,
  withRetina,
  additionalUrlParams = "",
}: {
  width: number;
  aspectRatio: string;
  src: string;
  withRetina: boolean;
  additionalUrlParams?: string;
}): string => {
  const withRetinaPart = withRetina ? ` 1x, ${src}?w=${width * 2}&ar=${aspectRatio}${additionalUrlParams} 2x` : "";
  return `${src}?w=${width}&ar=${aspectRatio}${additionalUrlParams}${withRetinaPart}`;
};

export const buildAdditionalUrlParams = ({
  fit,
  fillColor,
  grayscale,
  invert,
}: {
  fit?: IMAGE_FIT_TYPES;
  fillColor?: IMAGE_FILL_COLORS;
  grayscale?: boolean;
  invert?: boolean;
} = {}): string => {
  let additionalUrlParams = "&auto=format,compress";
  switch (fit) {
    case IMAGE_FIT_TYPES.crop:
      additionalUrlParams += "&fit=crop";
      break;
    case IMAGE_FIT_TYPES.fill:
      additionalUrlParams += "&fit=fill&fill=solid";
      break;
  }
  switch (fillColor) {
    case IMAGE_FILL_COLORS.white:
      additionalUrlParams += `&fill-color=ffffff`;
      break;
    case IMAGE_FILL_COLORS.transparent:
      break;
  }
  if (grayscale) {
    additionalUrlParams += `&monochrome=${MONOCHROME_HEX_COLOR}`;
  }
  if (invert) {
    additionalUrlParams += "&invert=true";
  }
  return additionalUrlParams;
};

/**
 * Reusable image component for responsive lazy-loaded images
 * @param props.baseSrc base path with handle
 * @param props.alt alternative text, may be undefined for presentation images
 * @param props.imageSizes define responsive image sizes (see: IMAGE_TRANSFORM_TYPES)
 * @param props.fit enable image cropping and fitting
 * @param props.contain enable object-fit: contain;
 * @param props.className optional class name
 * @param props.loading optional loading strategy attribute, default is lazy
 * @param props.grayscale optional monochrome filter (grayscale)
 * @param props.invert optional invert filter
 * @constructor
 */
const Image: React.FC<Props> = props => {
  const {
    baseSrc,
    alt,
    imageSizes,
    className,
    fit,
    contain = false,
    grayscale = false,
    invert = false,
    loading = "lazy",
  } = props;
  const isFill = isFillProps(props);
  const imageSizeMap = IMAGE_TRANSFORMER_MAP[imageSizes];
  const additionalUrlParams = buildAdditionalUrlParams({
    fit,
    fillColor: isFill ? props.fillColor : undefined,
    grayscale,
    invert,
  });

  return (
    <picture>
      {isExtensiveImageSizeDefinition(imageSizeMap) ? (
        <>
          <source
            media="screen and (min-width:1440px)"
            srcSet={buildSrc({
              width: imageSizeMap.desktopLrg,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <source
            media="screen and (min-width:1260px) and (max-width:1439px)"
            srcSet={buildSrc({
              width: imageSizeMap.desktop,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <source
            media="screen and (min-width:1024px) and (max-width:1259px)"
            srcSet={buildSrc({
              width: imageSizeMap.desktopSml,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <source
            media="screen and (min-width:700px) and (max-width:1023px)"
            srcSet={buildSrc({
              width: imageSizeMap.tablet,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <source
            media="screen and (min-width:450px) and (max-width:699px)"
            srcSet={buildSrc({
              width: imageSizeMap.phoneLrg,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <source
            media="screen and (max-width:449px)"
            srcSet={buildSrc({
              width: imageSizeMap.phone,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <img
            alt={alt}
            className={classNames("img", { contain }, className)}
            loading={loading}
            src={buildSrc({
              width: imageSizeMap.desktopLrg,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: false,
              additionalUrlParams,
            })}
          />
        </>
      ) : (
        <>
          <source
            srcSet={buildSrc({
              width: imageSizeMap.width,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: true,
              additionalUrlParams,
            })}
          />
          <img
            alt={alt}
            className={classNames("img", { contain }, className)}
            loading={loading}
            src={buildSrc({
              width: imageSizeMap.width,
              aspectRatio: imageSizeMap.aspectRatio,
              src: baseSrc,
              withRetina: false,
              additionalUrlParams,
            })}
          />
        </>
      )}
    </picture>
  );
};

export default Image;
