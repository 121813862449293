import React, { useContext } from "react";
import i18next from "i18next";
import { ArrayParam } from "use-query-params";
import SearchableSingleOptionSelect from "../../../visual-components/components/form/SearchableSingleOptionSelect";
import SearchableMultiOptionSelect from "../../../visual-components/components/form/SearchableMultiOptionSelect";
import { FilterItemReferenceData, FilterReferenceData } from "../../services/reference-data-aggregator/types";
import {
  BRAND_FACET_KEY,
  getBrandModelCount,
  MODEL_BRAND_SEPARATOR,
  MODEL_FACET_KEY,
} from "../../services/filter-types/brandModelFilterTypeHelpers";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import { VehicleSearchResponse } from "../../../algolia/services/vehicleSearchApi";
import { getOccurrenceFor } from "../../services/getOccurrenceFor";
import { useFilterStateContext } from "./FilterStateContext";
import FilterBlock from "./FilterBlock";

// If the Brands Filter is "Simple", only one brand model combination can be selected
// and unavailable brands arent shown. If a brand is selected, an "all" option is shown
export const SimpleBrandsFilterContext = React.createContext(false);

const FilterBox = ({
  filterId,
  modelBrandQuery,
  setModelBrand,
  addNewModelBrand,
  brands,
  models: modelData,
  ignoreInactive,
  canRemove,
  searchData,
  simpleBrandsFilter,
}: {
  filterId: string;
  modelBrandQuery: string;
  setModelBrand: (query: string | null) => void;
  addNewModelBrand?: () => void;
  brands: FilterItemReferenceData;
  models: Record<string, FilterItemReferenceData>;
  ignoreInactive: boolean;
  canRemove: boolean;
  searchData: VehicleSearchResponse | undefined;
  simpleBrandsFilter: boolean;
}) => {
  const queryParts = modelBrandQuery.split(MODEL_BRAND_SEPARATOR);
  const brand = queryParts[0];
  const hasSelectedBrand = !!brand;

  const models = modelData[brand]?.list || [];
  // todo: fallback would fail
  const modelMap = modelData[brand]?.map || {};

  const mappedModels = models.map(({ value, name }) => {
    return {
      value,
      name,
      inactive: ignoreInactive ? false : getOccurrenceFor(MODEL_FACET_KEY, name, searchData) === 0,
    };
  });

  const count = getBrandModelCount(queryParts);

  const modelValues = queryParts.slice(1);

  let filteredBrands = brands.list;
  if (simpleBrandsFilter) {
    filteredBrands = filteredBrands.filter(({ name }) => getOccurrenceFor(BRAND_FACET_KEY, name, searchData) > 0);

    if (filteredBrands.length <= 1) {
      filteredBrands = [
        { name: i18next.t("ALL"), value: "", nameNormalized: "", isBasicFilterValue: true },
        ...filteredBrands,
      ];
    }
  }

  return (
    <FilterBlock
      add={addNewModelBrand ? { label: i18next.t("ADD BRAND MODEL FILTER"), onClick: addNewModelBrand } : undefined}
      className="filter__combi"
      count={count}
      filterId={filterId}
      title={i18next.t("BRAND MODEL FILTER")}
      remove={
        canRemove
          ? () => {
              setModelBrand(null);
            }
          : undefined
      }
    >
      <div className="box">
        <SearchableSingleOptionSelect
          fallbackValue={i18next.t("ALL")}
          label={i18next.t("BRAND TITLE")}
          options={filteredBrands.map(({ value, name }) => ({
            value,
            name,
            inactive: ignoreInactive ? false : getOccurrenceFor(BRAND_FACET_KEY, name, searchData) === 0,
          }))}
          value={
            hasSelectedBrand
              ? {
                  value: brands.map[brand].value,
                  name: brands.map[brand].name,
                }
              : undefined
          }
          onChange={({ value }) => {
            setModelBrand(value!);
          }}
        />

        <SearchableMultiOptionSelect
          disabled={!hasSelectedBrand || mappedModels.length === 0}
          fallbackValue={i18next.t("SELECT ALL")}
          label={i18next.t("MODEL TITLE")}
          options={mappedModels}
          values={modelValues.map(modelId => modelMap[modelId])}
          onChange={(name, checked) => {
            let updatedValues;
            if (checked) {
              updatedValues = [...modelValues, name];
            } else {
              updatedValues = modelValues.filter(val => val !== name);
            }
            setModelBrand([brand, ...updatedValues].join(MODEL_BRAND_SEPARATOR));
          }}
        />
      </div>
    </FilterBlock>
  );
};

const MAX_BRAND_MODEL_FILTERS = 3;

const getBrandModelFilterId = (index: number): string => {
  return `brandModel-${index}`;
};

type Props = {
  queryParam: string;
  data: FilterReferenceData;
  searchData: VehicleSearchResponse | undefined;
};

const BrandModelFilter: React.FC<Props> = ({ queryParam, data, searchData }) => {
  const [persistedModelBrands, setModelBrands] = useFilterQueryParam(queryParam, ArrayParam);
  let modelBrands = (persistedModelBrands as string[]) || [];
  const noBrandModelFilterApplied = modelBrands.length === 0;
  if (noBrandModelFilterApplied) {
    modelBrands = [""];
  }

  const simpleBrandsFilter = useContext(SimpleBrandsFilterContext);

  const { setOpenFilterId } = useFilterStateContext();

  return (
    <>
      {modelBrands.map((modelBrandQuery, i) => {
        const isLastItem = i === modelBrands.length - 1;
        const hasReachedMax = modelBrands.length >= MAX_BRAND_MODEL_FILTERS;
        const canAdd = !simpleBrandsFilter && isLastItem && !hasReachedMax;
        // ignore inactive styling when not in first item
        // todo: consider enabling inactive styling again if only one brand is selected
        const ignoreInactive = true; //i !== 0 || ;
        return (
          <FilterBox
            key={i}
            brands={data.brands}
            canRemove={!noBrandModelFilterApplied}
            filterId={getBrandModelFilterId(i)}
            ignoreInactive={ignoreInactive}
            modelBrandQuery={modelBrandQuery}
            models={data.models}
            searchData={searchData}
            simpleBrandsFilter={simpleBrandsFilter}
            addNewModelBrand={
              canAdd
                ? () => {
                    setModelBrands([...modelBrands, ""]);
                    setOpenFilterId(getBrandModelFilterId(i + 1));
                  }
                : undefined
            }
            setModelBrand={newModelBrandQuery => {
              if (newModelBrandQuery === null) {
                const reducedModelBrandQuery = modelBrands.filter((_, modelBrandIndex) => modelBrandIndex !== i);
                setModelBrands(reducedModelBrandQuery);
                setOpenFilterId(getBrandModelFilterId(0));
              } else {
                const newBrands = [...modelBrands];
                newBrands[i] = newModelBrandQuery;
                setModelBrands(newBrands);
              }
            }}
          />
        );
      })}
    </>
  );
};

export default BrandModelFilter;
