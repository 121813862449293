import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { logger } from "../scripts/logger";

const RELEASE_HASH = import.meta.env.VITE_COMMIT_HASH;
const RELEASE_NAME = import.meta.env.RELEASE_NAME;
const email = "info@carmarket.ch";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    logger.error("AppErrorBoundary", { exception: error, version: { hash: RELEASE_HASH, name: RELEASE_NAME } });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Helmet>
            <meta content="404" name="prerender-status-code" />
          </Helmet>
          <div className="page-container">
            <div className="container">
              <div className="col-span-10">
                <h1>Oops! Something went wrong.</h1>
                <p>
                  Please try again later. If this error occurs again please contact us by e-mail:{" "}
                  <a href={`mailto:${email}`}>{email}</a>.
                </p>
                <Link to="/" onClick={() => this.setState({ hasError: false })}>
                  Go to home page
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}
