import { gql } from "graphql-request";
import vehicleDetailFragment from "../fragments/vehicleDetailFragment";
import {
  AugmentedDetailVehicle,
  AugmentedDetailVehicleResponse,
  DetailVehicle,
  MinimalVehicle,
} from "../../vehicle/types/vehicle";
import transformDealerResponse from "../helpers/transformDealerReponse";
import pageNestedSlugFragment from "../fragments/pageNestedSlugFragment";
import { hygraphApi } from "./hygraphApi";

export const vehicleDetailApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    vehicle: builder.query<AugmentedDetailVehicle | null, string>({
      providesTags: ["languageDependent"],
      query: id => {
        return {
          document: gql`
            ${pageNestedSlugFragment}
            ${vehicleDetailFragment}
            query Vehicle($id: Int!, $includeOpeningHours: Boolean!, $includeReviews: Boolean!) {
              vehicle(where: { vehicleId: $id }) {
                ...VehicleDetails
                localizations(includeCurrent: true) {
                  locale
                  slug
                }
                teaser
                description
              }
            }
          `,
          variables: {
            id: parseInt(id),
            includeOpeningHours: true,
            includeReviews: true,
          },
        };
      },
      transformResponse: (response: {
        vehicle: AugmentedDetailVehicleResponse | null;
      }): AugmentedDetailVehicle | null => {
        if (response.vehicle === null || response.vehicle.dealer === null) {
          return null;
        }
        const { dealer, ...rest } = response.vehicle;
        return {
          ...rest,
          dealer: transformDealerResponse(dealer),
        };
      },
    }),
    vehicles: builder.query<DetailVehicle[], string[]>({
      providesTags: ["languageDependent"],
      query: ids => {
        return {
          document: gql`
            ${pageNestedSlugFragment}
            ${vehicleDetailFragment}
            query Vehicle($ids: [Int!], $includeOpeningHours: Boolean!, $includeReviews: Boolean!) {
              vehicles(where: { vehicleId_in: $ids }) {
                ...VehicleDetails
              }
            }
          `,
          variables: {
            ids: ids.map(id => parseInt(id)),
            includeOpeningHours: false,
            includeReviews: false,
          },
        };
      },
      transformResponse: (response: { vehicles: DetailVehicle[] }) => response.vehicles,
    }),
    minimalVehicles: builder.query<MinimalVehicle[], string[]>({
      providesTags: ["languageDependent"],
      query: ids => {
        return {
          document: gql`
            query Vehicle($ids: [Int!]) {
              vehicles(where: { vehicleId_in: $ids }) {
                slug
                vehicleId
                title
              }
            }
          `,
          variables: {
            ids: ids.map(id => parseInt(id)),
          },
        };
      },
      transformResponse: (response: { vehicles: MinimalVehicle[] }) => response.vehicles,
    }),
  }),
});

export const { useVehicleQuery, useVehiclesQuery, useMinimalVehiclesQuery } = vehicleDetailApi;
