import React, { ReactNode } from "react";
import classNames from "classnames";
import i18next from "i18next";
import { Icon } from "../../common/types/Icons";
import ContextLink from "../../common/components/ContextLink";

type ButtonBaseProps = {
  className?: string;
  children: ReactNode;
  transparent?: boolean;
  center?: boolean;
  disabled?: boolean;
  icon?: Icon;
  mobileText?: string;
  toast?: number;
  secondary?: boolean;
  noBorder?: boolean;
  // if setting isLoading, ensure to also set disabled
  isLoading?: boolean;
  reverse?: boolean;
};

const VisualButton: React.FC<
  Omit<ButtonBaseProps, "transparent" | "disabled" | "className" | "center" | "secondary">
> = ({ children, icon, mobileText, toast, isLoading }) => {
  return (
    <>
      {icon ? <span className={classNames("ifont", `ifont--${icon}`)} /> : null}
      <span className="btn__text">
        {isLoading ? `${i18next.t("LOADING")}…` : children}
        {toast ? <span className="circle-counter">{toast}</span> : null}
      </span>
      {mobileText ? <span className="btn__text btn__text--mobile">{mobileText}</span> : null}
    </>
  );
};

const buildBaseButtonClassName = ({
  className,
  transparent = false,
  disabled = false,
  center = false,
  secondary = false,
  noBorder = false,
  reverse = false,
}: {
  className?: string;
  transparent?: boolean;
  disabled?: boolean;
  center?: boolean;
  secondary?: boolean;
  noBorder?: boolean;
  reverse?: boolean;
}) => {
  return classNames(
    "btn",
    {
      inactive: disabled,
      transparent: transparent,
      "btn--center": center,
      "btn--secondary": secondary,
      "no-border": noBorder,
      "btn--reverse": reverse,
    },
    className,
  );
};

const Button: React.FC<
  { type?: "submit" | "button"; onClick?: React.MouseEventHandler<HTMLButtonElement> } & ButtonBaseProps
> = ({ type, disabled, transparent, onClick, className, secondary, center, noBorder, reverse, ...rest }) => {
  return (
    <button
      className={buildBaseButtonClassName({ transparent, disabled, secondary, className, center, noBorder, reverse })}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      <VisualButton {...rest} />
    </button>
  );
};

export const ButtonLink: React.FC<
  {
    to: string;
    state?: Record<string, number | boolean>;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    target?: string;
    rel?: string;
  } & ButtonBaseProps
> = ({
  to,
  state,
  transparent,
  secondary,
  disabled,
  className,
  center,
  noBorder,
  onClick,
  target,
  rel,
  reverse,
  ...rest
}) => {
  return (
    <ContextLink
      className={buildBaseButtonClassName({ transparent, disabled, secondary, className, center, noBorder, reverse })}
      rel={rel}
      state={state}
      target={target}
      to={to}
      onClick={onClick}
    >
      <VisualButton {...rest} />
    </ContextLink>
  );
};

export const ButtonAnchor: React.FC<
  {
    href?: string;
    target?: string;
    rel?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  } & ButtonBaseProps
> = ({
  href,
  target,
  rel,
  transparent,
  disabled,
  className,
  secondary,
  center,
  noBorder,
  onClick,
  reverse,
  ...rest
}) => {
  return (
    <a
      className={buildBaseButtonClassName({ transparent, disabled, secondary, className, center, noBorder, reverse })}
      href={href}
      rel={rel}
      target={target}
      onClick={onClick}
    >
      <VisualButton {...rest} />
    </a>
  );
};

export const LinkIconButton: React.FC<
  {
    icon: Icon;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    inactive?: boolean;
  } & ButtonBaseProps
> = ({ children, onClick, icon, inactive, className, ...rest }) => {
  return (
    <button className={classNames("link-icon", className, { inactive })} onClick={onClick} {...rest}>
      <span className={classNames("ifont", `ifont--${icon}`)} />
      <span className="link-icon__text">{children}</span>
    </button>
  );
};

export default Button;
