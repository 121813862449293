import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";
import { VehicleDiscount, VehiclePromotion } from "../../../modules/hygraph/vo";

export type TestDriveOrigin = "BRAND" | "MODEL" | "VEHICLE";

export type TestDriveRequest = {
  modelId: number;
  dealerId: number;
  vehicleId: number;
  dueDate: string;
  dueTime: string;
  comment?: string;
  firstName: string;
  lastName: string;
  salutation: string;
  email: string;
  phone?: string;
  utmCampaign?: string;
  origin: TestDriveOrigin;
};

export type TestDriveMarketplaceRequest = Omit<TestDriveRequest, "vehicleId">;

export type TestDriveResponse = {
  status: "ok";
  date: string;
};

export type InquiryOrigin = "VEHICLE" | "DEALER" | "MODEL";

export type GeneralInquiryRequest = {
  modelId?: number;
  vehicleId: number | undefined;
  dealerId: number;
  comment?: string;
  firstName: string;
  lastName: string;
  salutation: string;
  email: string;
  phone?: string;
  utmCampaign?: string;
  origin: InquiryOrigin;
};

export type GeneralInquiryResponse = {
  status: "ok";
};

export type NewVehicleInquiryRequest = Omit<GeneralInquiryRequest, "vehicleId">;

export type NewVehicleInquiryResponse = {
  status: "ok";
};

export type VehicleEvaluationRequest = {
  vin: string;
  firstRegistration: string;
  mileage: number;
  firstName: string;
  lastName: string;
  salutation: string;
  email: string;
  phone?: string;
  dealerId: number;
  dueDate: string;
  dueTime: string;
  utmCampaign?: string;
};

export type VehicleEvaluationResponse = {
  status: "ok";
  dueDate: string;
  dueTime: string;
};

export type LeasingRequestSummaryRequest = {
  vehicleId: number;
  downPayment: number;
  kmPerYear: number;
  totalRuntime: number;
};
export type LeasingRequestSummaryResponse = {
  data: {
    originalPrice: number;
    priceB2CGross: number;
    pricePreparationGross: number;
    discounts: VehicleDiscount[];
    promotions: VehiclePromotion[];
    financingAmount: number;
    leasingInterest: number;
    monthlyLeasingRate: number;
    downPayment: number;
    residualValue: number;
    kmPerYear: number;
    totalRuntime: number;
    legalDetailLines: string[];
  };
};

export type LeasingRequestCheckoutRequest = {
  vehicleId: number;
  downPayment: number;
  kmPerYear: number;
  totalRuntime: number;
  firstName: string;
  lastName: string;
  salutation: string;
  email: string;
  phone: string | undefined;
  tradeInBrand: string | undefined;
  tradeInModel: string | undefined;
  tradeInFirstRegistration: string | undefined;
  tradeInMileage: number | undefined;
  tradeInDueDate: string | undefined;
  tradeInDueTime: string | undefined;
  utmCampaign?: string;
};

export type ModelDownloadDocumentRequest = {
  firstName: string;
  lastName: string;
  salutation: string;
  email: string;
  modelId: number;
  documentId: number;
  utmCampaign?: string;
};

export type ModelDownloadDocumentResponse = Record<string, never>;

export const ffuApi = createApi({
  reducerPath: "ffuApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    bookTestDrive: builder.mutation<TestDriveResponse, TestDriveRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/test-drive",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    bookTestDriveMarketplace: builder.mutation<TestDriveResponse, TestDriveMarketplaceRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/test-drive-marketplace",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    createGeneralInquiry: builder.mutation<GeneralInquiryResponse, GeneralInquiryRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/general-inquiry",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    createNewVehicleInquiry: builder.mutation<NewVehicleInquiryResponse, NewVehicleInquiryRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/new-vehicle-inquiry",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    vehicleEvaluation: builder.mutation<VehicleEvaluationResponse, VehicleEvaluationRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/vehicle-evaluation",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    getLeasingRequestSummary: builder.query<LeasingRequestSummaryResponse, LeasingRequestSummaryRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/leasing/request/summary",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    createLeasingRequest: builder.mutation<null, LeasingRequestCheckoutRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/leasing/request",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    modelDocumentDownloadRequest: builder.mutation<ModelDownloadDocumentResponse, ModelDownloadDocumentRequest>({
      query: data => ({
        method: "POST",
        url: "/ffu/model-document-download",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
  }),
});

export const {
  useBookTestDriveMutation,
  useBookTestDriveMarketplaceMutation,
  useCreateGeneralInquiryMutation,
  useCreateNewVehicleInquiryMutation,
  useVehicleEvaluationMutation,
  useGetLeasingRequestSummaryQuery,
  useCreateLeasingRequestMutation,
  useModelDocumentDownloadRequestMutation,
} = ffuApi;
