import { Page, RootPath } from "../../modules/hygraph/vo";
import Locale, { mapHygraphLocale } from "../../modules/common/constants/Locale";
import { Page as PageType } from "../../router/constants/Page";
import { getPathBuilders } from "../../router/helpers/pathBuilderHelpers";
import { AUTH_BASE_URL } from "../../auth/constants";

export type ValidStaticPagePageTypes =
  | PageType.staticGeneral
  | PageType.staticYourVehicle
  | PageType.staticBrand
  | PageType.staticModel;

export const getFiltersByPageType = (
  pageType: ValidStaticPagePageTypes,
  slugs: string[],
  brandSlug?: string,
  modelSlug?: string,
): string => {
  switch (pageType) {
    case PageType.staticModel:
      return `
      ${buildNestedSlugsClause(slugs)}
      ${buildNestedParentPageClause(
        slugs.length,
        `{parent: {Model: {slug: "${modelSlug}", brand: {slug: "${brandSlug}"}}}}`,
      )}
      `;
    case PageType.staticBrand:
      return `
      ${buildNestedSlugsClause(slugs)}
      ${buildNestedParentPageClause(slugs.length, `{parent: {Brand: {slug: "${brandSlug}"}}}`)}
      `;
    case PageType.staticGeneral:
      return `
      ${buildNestedSlugsClause(slugs)}
      ${buildNestedParentPageClause(slugs.length, `{parent: {Root: {rootPath: ${RootPath.General}}}}`)}
      `;
    case PageType.staticYourVehicle:
      return `
      ${buildNestedSlugsClause(slugs)}
      ${buildNestedParentPageClause(slugs.length, `{parent: {Root: {rootPath: ${RootPath.Yourvehicle}}}}`)}
      `;
  }
};

const buildNestedParentPageClause = (depth: number, content: string): string => {
  if (depth > 1) {
    return `{parent: {Page: ${buildNestedParentPageClause(depth - 1, content)}}}`;
  } else {
    return content;
  }
};

export const buildNestedSlugsClause = (slugs: string[], level: number = 1): string => {
  const lastSlug = slugs[slugs.length - level];
  const clauseToNest = slugs.length > level ? ` parent: {Page: ${buildNestedSlugsClause(slugs, level + 1)}}` : "";
  return `{slug: "${lastSlug}"${clauseToNest}}`;
};

export const buildNestedRootPathClause = (rootPath: string, depth: number): string => {
  if (depth > 1) {
    return `{parent: {Page: ${buildNestedRootPathClause(rootPath, depth - 1)}}}`;
  }
  return `{parent: {Root: {rootPath: ${rootPath}}}}`;
};

export const buildNestedSlugObtainer = (depth: number): string => {
  const slugLocalizations = `
  slug
  localizations(includeCurrent: true) {
    locale
    slug
  }`;
  if (depth > 0) {
    return `
    ${slugLocalizations}
    parent {
      __typename
      ...on Root {
        rootPath
      }
      ...on Model {
        slug
        brand {
          slug
        }
      }
      ...on Brand {
        slug
      }
      ...on Page {
        ${buildNestedSlugObtainer(depth - 1)}
      }
    }`;
  }
  return slugLocalizations;
};

export const buildLocalizedUrl = (page: Page, locale: Locale): string => {
  const slug = page.localizations.find(localization => mapHygraphLocale(localization.locale) === locale)?.slug;
  if (page.parent?.__typename === "Page") {
    return `${buildLocalizedUrl(page.parent!, locale)}/${slug}`;
  }

  const pathBuilders = getPathBuilders(locale, import.meta.env.VITE_APP_BASE_URL!, AUTH_BASE_URL);
  return pathBuilders.staticPagePath({ slug: slug!, parent: page.parent! });
};
