import React, { useEffect } from "react";
import classNames from "classnames";
import i18next from "i18next";
import useCarousel from "../hooks/useCarousel";
import { getThumbnailForVideo } from "../../../magazine/services/video-helper";
import isNil from "../helpers/isNil";
import Image, {
  IMAGE_FILL_COLORS,
  IMAGE_FIT_TYPES,
  IMAGE_TRANSFORM_TYPES,
} from "../../visual-components/components/Image";
import { Key } from "../constants/Key";
import useEvent from "../hooks/useEvent";
import CarouselNavDot from "./CarouselNavDot";
import YoutubePlayer from "./YoutubePlayer";
import CarouselArrows from "./CarouselArrows";
import ReservedTag from "./ReservedTag";

export type VideoMedia = {
  videoId: string;
  caption: string;
};

export type ImageMedia = {
  baseSrc: string;
  alt?: string;
};

const isVideoMedia = (media: ImageMedia | VideoMedia): media is VideoMedia => {
  return !isNil((media as VideoMedia).videoId);
};

type Props = {
  media: (ImageMedia | VideoMedia)[];
  className: string;
  isDetailView?: boolean;
  showGalleryOverlay?: (index?: number) => void;
  imageSizes: IMAGE_TRANSFORM_TYPES;
  appliedIndex?: number;
  title?: string;
  reserved?: boolean;
  hideCaption?: boolean;
};

// this is based on the styling
const SHOWN_THUMBNAILS = 5;

const Slideshow: React.FC<Props> = ({
  imageSizes,
  media,
  className,
  isDetailView = false,
  showGalleryOverlay,
  appliedIndex,
  title,
  reserved,
  hideCaption,
}) => {
  const { activeCarouselItem, scrollPosition, carouselScrollContainerRef, scrollTo, next, prev } = useCarousel(
    media,
    ".slideshow__item",
  );
  const { carouselScrollContainerRef: thumbnailCarouselScrollContainerRef, scrollTo: scrollToThumbnail } = useCarousel(
    media,
    ".slideshow-thumb__item",
    Math.floor(SHOWN_THUMBNAILS / 2),
  );

  useEffect(() => {
    // -1 because thumbnail of first image is hidden
    scrollToThumbnail(activeCarouselItem - 1);
  }, [activeCarouselItem, scrollToThumbnail]);

  useEffect(() => {
    if (appliedIndex !== undefined) {
      scrollTo(appliedIndex ?? 0);
    }
  }, [appliedIndex, scrollTo]);

  const arrowKeyNavigation = useEvent((key: Key) => {
    if (key === Key.ArrowLeft) {
      prev();
    } else {
      next();
    }
  });
  useEffect(() => {
    if (!isDetailView) {
      return;
    }
    const listener = (event: KeyboardEvent) => {
      if (event.key === Key.ArrowLeft || event.key === Key.ArrowRight) {
        // preventDefault as it would otherwise scroll the containers (especially thumbnail container if it is focused)
        event.preventDefault();
        arrowKeyNavigation(event.key);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isDetailView, arrowKeyNavigation]);

  const hasMedia = media.length > 0;

  return (
    <div className={classNames("slideshow-container", className)}>
      <ReservedTag isReserved={reserved} />
      <CarouselArrows
        next={next}
        nextClassName="slideshow__nav slideshow__nav--right"
        prev={prev}
        prevClassName="slideshow__nav slideshow__nav--left"
        scrollPosition={scrollPosition}
        totalItems={media.length}
        trackingTitle={title ?? ""}
      />
      <div className="slideshow__main ">
        <div ref={carouselScrollContainerRef} className="slideshow__main-row">
          {hasMedia ? null : <div className="slideshow__item no-img" />}
          {media.map((item, i) => {
            const isFirstItem = i === 0;
            const isNextItem = i === activeCarouselItem + 1;
            const loadingBehavior = isFirstItem || isNextItem ? "eager" : undefined;
            if (isVideoMedia(item)) {
              return (
                <div key={item.videoId + i} className="slideshow__item">
                  <YoutubePlayer caption={item.caption} videoId={item.videoId} />
                </div>
              );
            } else {
              return (
                <div
                  key={item.baseSrc + i}
                  className="slideshow__item"
                  onClick={
                    showGalleryOverlay
                      ? () => {
                          showGalleryOverlay(i);
                        }
                      : undefined
                  }
                >
                  {isDetailView ? (
                    <Image
                      alt={item.alt}
                      baseSrc={item.baseSrc}
                      contain={isDetailView}
                      fillColor={IMAGE_FILL_COLORS.transparent}
                      fit={IMAGE_FIT_TYPES.fill}
                      imageSizes={imageSizes}
                      loading={loadingBehavior}
                    />
                  ) : (
                    <Image
                      alt={item.alt}
                      baseSrc={item.baseSrc}
                      contain={isDetailView}
                      fit={IMAGE_FIT_TYPES.crop}
                      imageSizes={imageSizes}
                      loading={loadingBehavior}
                    />
                  )}
                </div>
              );
            }
          })}
        </div>
        <div className="slideshow__thumb-row-wrap">
          <div ref={thumbnailCarouselScrollContainerRef} className="slideshow__thumb-row">
            {media.map((item, galleryIndex) => {
              const isVideo = isVideoMedia(item);
              const videoId = isVideo ? item.videoId : "";
              return (
                <div
                  key={galleryIndex}
                  className={classNames("slideshow-thumb__item", {
                    active: galleryIndex === activeCarouselItem,
                    video: isVideo,
                  })}
                  onClick={() => {
                    scrollTo(galleryIndex);
                  }}
                >
                  {isVideo ? (
                    <img alt="" className="slideshow-thumb-item__img" src={getThumbnailForVideo(videoId)} />
                  ) : (
                    <Image
                      alt={item.alt}
                      baseSrc={item.baseSrc}
                      fit={IMAGE_FIT_TYPES.crop}
                      imageSizes={
                        isDetailView
                          ? IMAGE_TRANSFORM_TYPES.overlayThumbnailImage
                          : IMAGE_TRANSFORM_TYPES.thumbnailImage
                      }
                    />
                  )}
                  {isVideo ? (
                    <span className="slideshow-thumb__video-play-btn">
                      <span className="ifont ifont--caret_right " />
                    </span>
                  ) : null}
                </div>
              );
            })}
          </div>
          {isDetailView || media.length < 5 ? null : (
            <button
              aria-label={i18next.t("SHOW GALLERY")}
              className="slideshow__thumb-row-button"
              onClick={
                showGalleryOverlay
                  ? () => {
                      showGalleryOverlay(0);
                    }
                  : undefined
              }
            >
              <span className="ifont ifont--camera" />
            </button>
          )}
        </div>
        {isDetailView ? null : (
          <div className="slideshow__nav-dot">
            <CarouselNavDot currentItem={activeCarouselItem} scrollTo={scrollTo} totalItems={media.length} />
          </div>
        )}
      </div>
      {isDetailView ? null : (
        <div className="slideshow__caption-row">
          {hideCaption ? <>&nbsp;</> : ((media[activeCarouselItem] as ImageMedia)?.alt ?? <>&nbsp;</>)}
        </div>
      )}
    </div>
  );
};

export default Slideshow;
