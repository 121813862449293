import { STORAGE_KEYS } from "../../common/constants/Storage";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import useEvent from "../../common/hooks/useEvent";

const LIST_SIZE = 8;

export type ViewedVehicleEntry = {
  id: string;
  slug: string | undefined | null;
  title: string;
};

function useViewedVehicleList() {
  const [lastViewedList, setLastViewedList] = useLocalStorage<ViewedVehicleEntry[]>(STORAGE_KEYS.LAST_VIEWED_LIST, []);

  const recordView = useEvent((vehicle: ViewedVehicleEntry) => {
    const newVehicles = lastViewedList.filter(({ id }) => id !== vehicle.id);
    setLastViewedList([vehicle, ...newVehicles].slice(0, LIST_SIZE));
  });

  const ids = lastViewedList.map(({ id }) => id);

  return {
    lastViewedList,
    recordView,
    /**
     * untracked overwrite of the last viewed list. To be used for cleanup purposes.
     * For user tracking, use recordView instead.
     */
    setLastViewedList,
    ids,
  };
}

export default useViewedVehicleList;
