import React from "react";
import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { ContentModule } from "../../constants/ContentModule";
import { Page } from "../../../../router/constants/Page";

type CtaTrackEvent = {
  event: "gaEvent";
  event_name: "content_modules";
  page_type?: Page | null;
  content_modules: {
    content_module: ContentModule.cta;
    content_module_action: "outboundClick";
    content_module_label: string;
    content_module_text?: string;
    content_module_target_url?: string;
  };
};
export type CtaTrackingEvent = BaseTrackingEvent & CtaTrackEvent;

export const ctaOutboundLinkTrack = ({
  ev,
  pageType,
  clickTrackText,
  targetUrl,
}: {
  ev: React.MouseEvent<HTMLSpanElement>;
  pageType?: Page | null;
  clickTrackText?: string;
  targetUrl?: string;
}) => {
  const linkLabel = (ev.target as HTMLElement)?.innerText;

  dataLayer([
    {
      event: "gaEvent",
      event_name: "content_modules",
      page_type: pageType,
      content_modules: {
        content_module: ContentModule.cta,
        content_module_action: "outboundClick",
        content_module_label: linkLabel ?? "",
        content_module_text: clickTrackText ?? "",
        content_module_target_url: targetUrl,
      },
    } satisfies CtaTrackingEvent,
  ]);
};
