import React from "react";
import i18next from "i18next";
import useFormatter from "../../common/hooks/useFormatter";
import { Currency, VehiclePromotion } from "../../hygraph/vo";
import { notNil } from "../../common/helpers/isNil";
import { isPaPromotionValid } from "../helpers/isPaPromotionValid";

export const UNKNOWN_PROMOTION_PRICE = -1;

type Props = {
  promotion: VehiclePromotion;
  currency: Currency;
  openPromotionInfo: () => void;
};

const PromotionInfoDetail: React.FC<Props> = ({ promotion, currency, openPromotionInfo }) => {
  const { formatCurrency } = useFormatter();

  const priceGrossText =
    promotion.priceGross === UNKNOWN_PROMOTION_PRICE ? "" : formatCurrency(promotion.priceGross, currency);

  const paPromotion = promotion.paPromotion;
  const hasPaPromotion = notNil(paPromotion);
  const displayPromotion = !hasPaPromotion || isPaPromotionValid(paPromotion);

  if (!displayPromotion) {
    return null;
  }

  return (
    <p>
      <strong>{promotion.name}</strong>
      {priceGrossText}
      {hasPaPromotion ? (
        <button className="ifont ifont--info" onClick={openPromotionInfo}>
          <span className="vshidden">{i18next.t("SHOW ALL")}</span>
        </button>
      ) : null}
    </p>
  );
};

export default PromotionInfoDetail;
