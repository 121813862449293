import React, { useState } from "react";
import { Currency, VehiclePromotion } from "../../hygraph/vo";
import Popup from "../../visual-components/components/Popup";
import DialogPortal from "../../common/components/DialogPortal";
import { notNil } from "../../common/helpers/isNil";
import PromotionInfoBody from "./PromotionInfoBody";
import PromotionInfo from "./PromotionInfo";

type Props = {
  promotions: VehiclePromotion[];
  currency: Currency;
};

const Promotions: React.FC<Props> = ({ promotions, currency }) => {
  const [openPromotion, setOpenPromotion] = useState<VehiclePromotion | null>(null);

  return (
    <>
      <PromotionInfo
        currency={currency}
        promotions={promotions}
        openPromotionInfo={promotion => {
          setOpenPromotion(promotion);
        }}
      />
      <DialogPortal>
        <Popup keyboardDismissable close={() => setOpenPromotion(null)} open={notNil(openPromotion)}>
          {notNil(openPromotion) ? <PromotionInfoBody currency={currency} promotion={openPromotion} /> : null}
        </Popup>
      </DialogPortal>
    </>
  );
};

export default Promotions;
